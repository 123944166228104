import { IconCheck, IconCurrencyDollar } from "@tabler/icons-react";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../store";
import {
  checkSubscription,
  fetchUserInfo,
  getPlan,
  getUserInfo,
  isAuthenticated,
} from "../slices/authSlice";

const MLJAR_STUDIO_PRO_MONTH_ID = 900861;
const MLJAR_STUDIO_PRO_YEAR_ID = 907157;
const MLJAR_STUDIO_BUSINESS_MONTH_ID = 900862;
const MLJAR_STUDIO_BUSINESS_YEAR_ID = 907158;
const MLJAR_VENDOR_ID = 124786;

declare global {
  interface Window {
    Paddle: any;
  }
}

export default function PricingView() {
  // get variables from local storage
  const subscriptionTypeLocalStorage = localStorage.getItem("subscriptionType");
  const isMonthlyPlanLocalStorage = localStorage.getItem("isMonthlyPlan");
  //
  const [checkoutClose, setCheckoutClose] = useState(false);
  const dispatch = useAppDispatch();
  const user = useSelector(getUserInfo);
  const Paddle = window.Paddle;
  const plan = useSelector(getPlan);
  const navigate = useNavigate();
  const isUserLoggedIn = useSelector(isAuthenticated);
  const parseBoolean = (value: string | null) => value === "true";
  const [subscriptionType, setSubscriptionType] = useState(
    subscriptionTypeLocalStorage ? subscriptionTypeLocalStorage : "individual"
  );
  const [isMonthlyPlan, setIsMonthlyPlan] = useState(
    isMonthlyPlanLocalStorage ? parseBoolean(isMonthlyPlanLocalStorage) : true
  ); // 0 - yearly, 1- monthly
  useEffect(() => {
    if (isUserLoggedIn) {
      dispatch(checkSubscription());
      dispatch(fetchUserInfo());
    }
  }, [dispatch, isUserLoggedIn]);

  useEffect(() => {
    if (isUserLoggedIn && window.Paddle) {
      Paddle.Setup({
        vendor: MLJAR_VENDOR_ID,
        eventCallback: function (data: any) {
          if (data.event === "Checkout.Close") {
            dispatch(checkSubscription());
            setCheckoutClose(true);
          }
        },
      });
    }
  }, [isUserLoggedIn, dispatch]);

  // move to downloads after purchase sub
  useEffect(() => {
    if (plan !== "free" && checkoutClose) {
      navigate("/app/downloads");
    }
  }, [checkoutClose]);
  //

  const handleChangeSubscriptionType = (type: string) => {
    setSubscriptionType(type);
  };

  const handleBuyLicense = () => {
    let id;
    if (subscriptionType === "individual" && isMonthlyPlan) {
      id = MLJAR_STUDIO_PRO_MONTH_ID;
    } else if (subscriptionType === "individual" && !isMonthlyPlan) {
      id = MLJAR_STUDIO_PRO_YEAR_ID;
    } else if (subscriptionType === "business" && isMonthlyPlan) {
      id = MLJAR_STUDIO_BUSINESS_MONTH_ID;
    } else if (subscriptionType === "business" && !isMonthlyPlan) {
      id = MLJAR_STUDIO_BUSINESS_YEAR_ID;
    }

    Paddle.Checkout.open({
      product: id,
      email: user.email,
      customData: {
        userId: user.pk,
      },
    });
  };
  useEffect(() => {
    localStorage.removeItem("subscriptionType");
    localStorage.removeItem("isMonthlyPlan");
  }, []);
  return (
    <div>
      {/* pricing */}
      <div className="container">
        {/* header */}
        <div className="row">
          <div className="col-md-10 offset-md-1">
            <h1 className="display-6" style={{ fontWeight: "700" }}>
              <IconCurrencyDollar
                style={{ paddingBottom: "5px" }}
                size={"40"}
              />
              Pricing
            </h1>
            <hr className="mb-5" />
          </div>
        </div>

        {/* buttons */}
        <div className="row">
          <div className="col-md-10 offset-md-1">
            <div className="d-lg-flex flex-lg-row flex-column align-items-center justify-content-between text-center">
              {/* subscription type buttons */}
              <div className="btn-group d-flex justify-content-center mb-3 mb-lg-0">
                <button
                  className={`btn btn-outline-primary ${
                    subscriptionType === "academic" ? `active` : ``
                  }`}
                  onClick={() => handleChangeSubscriptionType("academic")}
                >
                  Academic
                </button>
                <button
                  className={`btn btn-outline-primary ${
                    subscriptionType === "individual" ? `active` : ``
                  }`}
                  onClick={() => handleChangeSubscriptionType("individual")}
                >
                  Individual
                </button>
                <button
                  className={`btn btn-outline-primary ${
                    subscriptionType === "business" ? `active` : ``
                  }`}
                  onClick={() => handleChangeSubscriptionType("business")}
                >
                  Business
                </button>
              </div>

              {/* plan period buttons*/}
              <div className="btn-group mt-3 mt-lg-0">
                <button
                  className={`btn btn-outline-primary ${
                    isMonthlyPlan ? `active` : ``
                  }`}
                  onClick={(e) => setIsMonthlyPlan(true)}
                >
                  MONTHLY
                </button>
                <button
                  className={`btn btn-outline-primary ${
                    !isMonthlyPlan ? `active` : ``
                  }`}
                  onClick={(e) => setIsMonthlyPlan(false)}
                >
                  YEARLY
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* cards */}
        <div className="row row-cols-1 row-cols-md-2 g-4 justify-content-center align-items-stretch mb-5 mt-2 text-center mx-auto pricing">
          <div className="col d-flex align-items-stretch">
            <div
              className="card h-90 mb-4 rounded-3 shadow-sm mx-auto"
              style={{ width: "25rem" }}
            >
              <div className="card-header py-3">
                <h4 className="my-0 fw-normal">FREE</h4>
              </div>
              <div
                className="card-body"
                style={{ display: "flex", flexDirection: "column" }}
              >
                <h1
                  className="card-title pricing-card-title mt-5"
                  style={{ fontSize: "50px" }}
                >
                  $0
                </h1>
                <small className="text-body-secondary fw-light mb-5">
                  {isMonthlyPlan ? "per month" : "per year"}
                </small>
                <ul
                  className="list-unstyled mb-5 text-start fs-6"
                  style={{ marginLeft: 10 }}
                >
                  <li className="d-flex align-items-center mb-2 my-auto">
                    <IconCheck className="pricing-icon" />
                    MLJAR Studio Desktop app
                  </li>
                  <li className="d-flex align-items-center mb-2 my-auto">
                    <IconCheck className="pricing-icon" />
                    Variable Explorer
                  </li>
                  <li className="d-flex align-items-center mb-2 my-auto">
                    <IconCheck className="pricing-icon" />
                    Packages Manager
                  </li>
                  <li className="d-flex align-items-center mb-2 my-auto">
                    <IconCheck className="pricing-icon" />
                    Piece of Code recipes
                  </li>
                  <li className="d-flex align-items-center mb-2 my-auto">
                    <IconCheck className="pricing-icon" />
                    20 prompts to AI Assistant{" "}
                    {!isMonthlyPlan ? "(per month)" : ""}
                  </li>
                  <li className="d-flex align-items-center mb-2 my-auto">
                    <IconCheck className="pricing-icon" />
                    AGPLv3 license
                  </li>
                </ul>
                {!isUserLoggedIn ? (
                  <button
                    type="button"
                    className="btn btn-lg btn-outline-primary mx-auto"
                    style={{
                      width: "80%",
                      marginTop: "auto",
                      marginBottom: "10px",
                    }}
                    onClick={() => navigate("/app")}
                  >
                    Sign Up
                  </button>
                ) : (
                  <button
                    type="button"
                    className="btn btn-lg btn-outline-primary mx-auto"
                    style={{
                      width: "80%",
                      marginTop: "auto",
                      marginBottom: "10px",
                    }}
                    onClick={() => navigate("/app/downloads")}
                  >
                    Download
                  </button>
                )}
              </div>
            </div>
          </div>
          <div className="col d-flex align-items-stretch">
            <div
              className="card h-90 mb-4 rounded-3 shadow-sm border-primary mx-auto"
              style={{ width: "25rem" }}
            >
              <div className="card-header py-3 text-bg-primary border-primary">
                <h4 className="my-0 fw-normal">
                  {subscriptionType === "academic" ||
                  subscriptionType === "individual"
                    ? "PROFESSIONAL"
                    : "BUSINESS"}
                </h4>
              </div>
              <div
                className="card-body"
                style={{ display: "flex", flexDirection: "column" }}
              >
                {!isMonthlyPlan && (
                  <span
                    className="mt-4"
                    style={{ color: "red", fontWeight: 600 }}
                  >
                    SAVE 2 MONTHS!
                  </span>
                )}
                <h1
                  className={`card-title pricing-card-title ${
                    isMonthlyPlan ? `mt-5` : `mt-0`
                  }`}
                  style={{ fontSize: "50px" }}
                >
                  {isMonthlyPlan
                    ? subscriptionType === "academic"
                      ? "$10"
                      : subscriptionType === "individual"
                      ? "$20"
                      : subscriptionType === "business"
                      ? "$40"
                      : ""
                    : subscriptionType === "academic"
                    ? "$100"
                    : subscriptionType === "individual"
                    ? "$200"
                    : subscriptionType === "business"
                    ? "$400"
                    : ""}
                </h1>
                <small className="text-body-secondary fw-light mb-5">
                  {isMonthlyPlan ? "per month" : "per year"}
                </small>
                <ul
                  className="list-unstyled mb-5 text-start fs-6"
                  style={{ marginLeft: 10 }}
                >
                  <li className="d-flex align-items-center mb-2 my-auto">
                    <IconCheck className="pricing-icon" />
                    MLJAR Studio Desktop app
                  </li>
                  <li className="d-flex align-items-center mb-2 my-auto">
                    <IconCheck className="pricing-icon" />
                    Variable Explorer
                  </li>
                  <li className="d-flex align-items-center mb-2 my-auto">
                    <IconCheck className="pricing-icon" />
                    Packages Manager
                  </li>
                  <li className="d-flex align-items-center mb-2 my-auto">
                    <IconCheck className="pricing-icon" />
                    Piece of Code recipes
                  </li>
                  <li className="d-flex align-items-center mb-2 my-auto">
                    <IconCheck className="pricing-icon" />
                    {subscriptionType === "academic" ||
                    subscriptionType === "individual"
                      ? "250"
                      : "500"}{" "}
                    prompts to AI Assistant{" "}
                    {!isMonthlyPlan ? "(per month)" : ""}
                  </li>
                  <li className="d-flex align-items-center mb-2 my-auto">
                    <IconCheck className="pricing-icon" />
                    Commercial license
                  </li>
                  <li className="d-flex align-items-center mb-2 my-auto">
                    <IconCheck className="pricing-icon" />
                    Email support
                  </li>
                </ul>
                {isUserLoggedIn && plan !== "free" && (
                  <span className="" style={{ color: "red", fontWeight: 600 }}>
                    You already have a{" "}
                    <span
                      style={{ color: "green", textTransform: "uppercase" }}
                    >
                      {plan}
                    </span>{" "}
                    license.
                  </span>
                )}
                {isUserLoggedIn &&
                  plan === "free" &&
                  subscriptionType === "academic" && (
                    <span
                      className="mb-2"
                      style={{ color: "#0d6efd", fontWeight: 600 }}
                    >
                      Contact us to confirm your student status and get your
                      personal buying code.
                    </span>
                  )}
                {!isUserLoggedIn ? (
                  <button
                    type="button"
                    className="btn btn-lg btn-primary mx-auto"
                    style={{
                      width: "80%",
                      marginTop: "auto",
                      marginBottom: "10px",
                    }}
                    onClick={() => navigate("/app")}
                  >
                    Sign Up
                  </button>
                ) : subscriptionType === "academic" ? (
                  <div
                    title={
                      plan !== "free"
                        ? "Cancel the current subscription to buy another."
                        : "Contact us to verify that you are classified for an academic license."
                    }
                  >
                    <button
                      type="button"
                      className="btn btn-lg btn-primary mx-auto"
                      style={{
                        width: "80%",
                        marginTop: "auto",
                        marginBottom: "10px",
                      }}
                      onClick={() => navigate("/contact")}
                      disabled={plan !== "free"}
                    >
                      Contact us
                    </button>
                  </div>
                ) : (
                  <div
                    title={
                      plan !== "free"
                        ? "Cancel the current subscription to buy another."
                        : "Click to buy license."
                    }
                  >
                    <button
                      type="button"
                      className="btn btn-lg btn-primary mx-auto"
                      style={{
                        width: "80%",
                        marginTop: "auto",
                        marginBottom: "10px",
                      }}
                      onClick={() => handleBuyLicense()}
                      disabled={plan !== "free"}
                    >
                      Buy License
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        {/* FAQ */}
        <div>
          <h2 className="faq-title mb-3">Frequently Asked Questions</h2>
          <ul className="list-unstyled text-start faq mx-auto">
            <li className="faq-questions" style={{ fontWeight: 600 }}>
              What payment methods are available?
              <p
                className="faq-answers"
                style={{ fontWeight: 300, marginLeft: "20px" }}
              >
                You can pay securely with credit or debit card and Paypal.
              </p>
            </li>
            <li className="faq-questions" style={{ fontWeight: 600 }}>
              Why do I need to pay VAT?
              <p
                className="faq-answers"
                style={{ fontWeight: 300, marginLeft: "20px" }}
              >
                VAT is a required sales tax in some countries. Fill in your
                company details during checkout (or after getting the invoice)
                and the VAT will either be deducted or you can use the invoice
                to remit the VAT from your tax authority.
              </p>
            </li>
            <li className="faq-questions" style={{ fontWeight: 600 }}>
              What is your refund policy?
              <p
                className="faq-answers"
                style={{ fontWeight: 300, marginLeft: "20px" }}
              >
                We want you to be delighted with your purchase. If you aren't,
                notify us within 30 days of purchase and we will refund your
                full payment.
              </p>
            </li>

            <li className="faq-questions" style={{ fontWeight: 600 }}>
              Who owns the code created in MLJAR Studio?
              <p
                className="faq-answers"
                style={{ fontWeight: 300, marginLeft: "20px" }}
              >
                You! Regardless of whether you use the free, pro or business
                version of MLJAR Studio, all created code is yours and free to
                be used however you like, including commercially.
              </p>
            </li>

            <li className="faq-questions" style={{ fontWeight: 600 }}>
              More questions?
              <p
                className="faq-answers"
                style={{ fontWeight: 300, marginLeft: "20px" }}
              >
                Please contact us for any further questions by email{" "}
                <b>contact@mljar.com</b>
              </p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}
