import {
  UserType,
  checkSubscription,
  getPlan,
  cancelSubscription,
} from "../slices/authSlice";
import { IconCurrencyDollar } from "@tabler/icons-react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../store";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

type Props = {
  user: UserType;
};

export default function Subscription({ user }: Props) {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const plan = useSelector(getPlan);
  const [email, setEmail] = useState("");
  const [isCancelFormVisible, setIsCancelFormVisible] = useState(false);

  useEffect(() => {
    dispatch(checkSubscription());
  }, []);

  return (
    <div>
      <h4>
        <IconCurrencyDollar style={{ paddingBottom: "3px" }} />
        Subscription
      </h4>

      {user.profile.plan === "unknown" && <p>Loading ...</p>}
      {user.profile.plan !== "unknown" && (
        <>
          <p className="mb-3">
            You are using{" "}
            <span
              style={{
                color: "green",
                textTransform: "uppercase",
                fontWeight: "bold",
              }}
            >
              {plan}
            </span>{" "}
            plan.
          </p>

          {plan !== "free" && (
            <div>
              {isCancelFormVisible && (
                <>
                  <p className="mb-0">
                    Please write your email <b>{user.email}</b> in the text
                    input below to confirm subscription cancelation.
                  </p>
                  <input
                    type="text"
                    className="form-control my-2 "
                    placeholder="Write your email to confirm the cancelation"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <button
                    className="btn btn-danger mb-3"
                    onClick={(e) => {
                      dispatch(cancelSubscription());
                      window.location.reload();
                    }}
                    disabled={email !== user.email}
                  >
                    Cancel subscription
                  </button>
                  <button
                    className={`btn btn-primary ms-3 mb-3`}
                    onClick={(e) => {
                      setIsCancelFormVisible(false);
                    }}
                  >
                    Hide
                  </button>
                </>
              )}

              <button
                className={`btn btn-danger mb-3 ${
                  isCancelFormVisible ? `d-none` : ``
                }`}
                onClick={(e) => {
                  setIsCancelFormVisible(true);
                }}
              >
                Cancel subscription
              </button>
            </div>
          )}

          {plan !== "free" && (
            <p className="">
              If you want to upgrade or downgrade your subscription, please
              cancel your current subscription first and then proceed to
              purchase the plan that best suits your needs. <br /> If you have
              any questions or issues with subscriptions, contact us at:{" "}
              <b>contact@mljar.com</b>.
            </p>
          )}

          {plan === "free" && (
            <div>
              <h5>Paid plans</h5>
              <p>
                You can upgrade your plan by buying a new license. Just choose
                the plan you want and complete the payment. <br /> If you need
                help, feel free to contact us at: <b>contact@mljar.com</b>.
              </p>
              <button
                className="btn btn-success"
                onClick={(e) => navigate("/pricing")}
              >
                Check Paid Plans
              </button>
            </div>
          )}
        </>
      )}
    </div>
  );
}
