import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import Title from "../components/TitleChanger";
import { isAuthenticated } from "../slices/authSlice";

export default function HomeView() {
  const navigate = useNavigate();
  const isLogged = useSelector(isAuthenticated);
  return (
    <div>
      <Title pageTitle={""} />
      <div className="container">
        <div className="col-md-10 offset-md-1">
          <div className="row py-4">
            <div className="col my-auto">
              <h1 className="title fw-bold">Data Science Editor</h1>
              <p className="subtitle fw-light text-secondary">
                MLJAR Studio is a Python editor created to make Data Science and
                Machine Learning tasks easy.
              </p>
              {!isLogged ? (
                <div className="text-center text-md-start">
                  <button
                    className="btn btn-primary px-4 py-2"
                    onClick={() => navigate("/login")}
                  >
                    Login
                  </button>
                  <button
                    className="btn btn-outline-dark mx-2 px-4 py-2"
                    onClick={() => navigate("/register")}
                  >
                    Sign Up
                  </button>
                </div>
              ) : (
                <div className="text-center text-md-start">
                  <button
                    className="btn btn-primary px-4 py-2"
                    onClick={() => navigate("/app")}
                  >
                    Open Dashboard
                  </button>
                </div>
              )}
            </div>
            <div className="col my-auto d-none d-md-block text-center">
              <img
                alt="MLJAR Studio forever"
                src="/icon.svg"
                style={{ width: "80%" }}
              />
            </div>
          </div>

          <div className="row pt-3 mb-5 align-items-center">
            <div className="col-md-6">
              <div className="ratio ratio-16x9">
                <iframe
                  title="MLJAR Studio Demo - Highlights All Features"
                  src="https://www.youtube.com/embed/n86msCLPTEY"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </div>
            </div>
            <div className="col-md-6">
              <h3>Discover MLJAR Studio</h3>
              <p>
                Watch this comprehensive demo video that highlights all the key
                features of MLJAR Studio in action. Get a look at Interactive
                Code Recipes, AI Assistant, Variable Explorer and Packages
                Management.
              </p>
            </div>
          </div>

          {/* Features Section */}
          <div className="row pt-3">
            <div className="col-12">
              <h2 className="fw-bold">Features</h2>
            </div>
          </div>
          <div className="row pt-3 mb-3">
            {/* Feature 1 */}
            <div className="col-12 col-sm-6 col-md-4 mb-3">
              <div className="card h-100">
                <div className="card-body d-flex flex-column justify-content-center">
                  <h5 className="tiles-title">Desktop App with Python</h5>
                  <p className="tiles-content">
                    Fully managed Python environment within MLJAR Studio’s
                    desktop app.
                  </p>
                </div>
              </div>
            </div>
            {/* Feature 2 */}
            <div className="col-12 col-sm-6 col-md-4 mb-3">
              <div className="card h-100">
                <div className="card-body d-flex flex-column justify-content-center">
                  <h5 className="tiles-title">Interactive Code Recipes</h5>
                  <p className="tiles-content">
                    Access curated code recipes tailored for Machine Learning
                    projects.
                  </p>
                </div>
              </div>
            </div>
            {/* Feature 3 */}
            <div className="col-12 col-sm-6 col-md-4 mb-3">
              <div className="card h-100">
                <div className="card-body d-flex flex-column justify-content-center">
                  <h5 className="tiles-title">AI Assistant</h5>
                  <p className="tiles-content">
                    Get help from an AI assistant for your data analysis and
                    coding challenges.
                  </p>
                </div>
              </div>
            </div>
            {/* Feature 4 */}
            <div className="col-12 col-sm-6 col-md-4 mb-3">
              <div className="card h-100">
                <div className="card-body d-flex flex-column justify-content-center">
                  <h5 className="tiles-title">Variable Explorer</h5>
                  <p className="tiles-content">
                    Quickly inspect your workspace variables for efficient
                    coding.
                  </p>
                </div>
              </div>
            </div>
            {/* Feature 5 */}
            <div className="col-12 col-sm-6 col-md-4 mb-3">
              <div className="card h-100">
                <div className="card-body d-flex flex-column justify-content-center">
                  <h5 className="tiles-title">Packages Management</h5>
                  <p className="tiles-content">
                    Install, update, and manage Python packages directly within
                    your notebooks.
                  </p>
                </div>
              </div>
            </div>
            {/* Feature 6 */}
            <div className="col-12 col-sm-6 col-md-4 mb-3">
              <div className="card h-100">
                <div className="card-body d-flex flex-column justify-content-center">
                  <h5 className="tiles-title">Open Source</h5>
                  <p className="tiles-content">
                    Leverage the power of an open source desktop app and vibrant
                    community.
                  </p>
                </div>
              </div>
            </div>
          </div>

          {/* Get Started Projects Section */}
          <div className="row pt-3">
            <h2 style={{ fontWeight: "700" }}>Get Started Projects</h2>
          </div>

          {/* Project 1 */}
          <div className="row pt-3 mb-5 align-items-center">
            <div className="col-md-6">
              <div className="ratio ratio-16x9">
                <iframe
                  title="AutoML get started - train your first Machine Learning model in Python with MLJAR Studio"
                  src="https://www.youtube.com/embed/t_opxR5dbPU"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </div>
            </div>
            <div className="col-md-6">
              <h3>AutoML Get Started</h3>
              <p>
                Learn how to build and train your first machine learning model
                using MLJAR Studio's AutoML platform. This tutorial covers the
                essential steps from data preparation to model training.
              </p>
            </div>
          </div>

          {/* Project 2 */}
          <div className="row pt-3 mb-5 align-items-center">
            <div className="col-md-6 order-md-2">
              <div className="ratio ratio-16x9">
                <iframe
                  title="Advanced data analysis with ChatGPT in Python"
                  src="https://www.youtube.com/embed/N3-bs3sQ6jc"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </div>
            </div>
            <div className="col-md-6 order-md-1">
              <h3>Advanced Data Analysis with ChatGPT</h3>
              <p>
                Discover advanced techniques for data analysis using ChatGPT and
                Python. This video demonstrates how to leverage AI to gain
                insights from your data.
              </p>
            </div>
          </div>

          {/* Project 3 */}
          <div className="row pt-3 mb-5 align-items-center">
            <div className="col-md-6">
              <div className="ratio ratio-16x9">
                <iframe
                  title="Data visualization with AI assistant"
                  src="https://www.youtube.com/embed/55DqnTSvgXk"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </div>
            </div>
            <div className="col-md-6">
              <h3>Data Visualization with AI Assistant</h3>
              <p>
                Explore the integration of an AI assistant in creating
                interactive data visualizations. The tutorial guides you through
                best practices for effective visual storytelling.
              </p>
            </div>
          </div>

          {/* Documentation Section */}
          <div className="card my-4">
            <div className="card-body text-center">
              <h4 className="card-title">Documentation</h4>
              <p className="card-text">
                Learn more about MLJAR Studio by visiting our comprehensive
                documentation.
              </p>
              <a
                href="https://mljar.com/docs/"
                target="_blank"
                rel="noreferrer"
                className="btn btn-primary"
              >
                Visit Documentation
              </a>
              <a
                href="https://github.com/mljar/studio"
                target="_blank"
                rel="noreferrer"
                className="btn btn-secondary mx-2"
              >
                GitHub Repository
              </a>
            </div>
          </div>

          {/* Installation Section */}
          <div className="row pt-3">
            <h2 style={{ fontWeight: "700" }}>Install MLJAR Studio</h2>

            <p>
              MLJAR Studio can be installed on Windows, MacOS, and Linux. Watch
              the video below for a step-by-step guide.
            </p>
          </div>
          <div className="my-4">
            <div className="card-body">
              {/* OS Boxes */}
              <div className="row text-center mb-4">
                <div className="col-md-4 mb-3">
                  <div className="card">
                    <div className="card-body">
                      <h5 className="card-title">Windows</h5>
                      <p className="card-text">
                        Install MLJAR Studio on Windows.
                      </p>
                      <div>
                        <a
                          href="https://mljar.com/docs/install/#windows"
                          target="_blank"
                          rel="noreferrer"
                          className="btn btn-primary me-2"
                        >
                          Documentation
                        </a>
                        <a href="/app/downloads/" className="btn btn-secondary">
                          Download
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 mb-3">
                  <div className="card">
                    <div className="card-body">
                      <h5 className="card-title">MacOS</h5>
                      <p className="card-text">
                        Install MLJAR Studio on MacOS.
                      </p>
                      <div>
                        <a
                          href="https://mljar.com/docs/install/#mac"
                          target="_blank"
                          rel="noreferrer"
                          className="btn btn-primary me-2"
                        >
                          Documentation
                        </a>
                        <a href="/app/downloads/" className="btn btn-secondary">
                          Download
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 mb-3">
                  <div className="card">
                    <div className="card-body">
                      <h5 className="card-title">Linux</h5>
                      <p className="card-text">
                        Install MLJAR Studio on Linux.
                      </p>
                      <div>
                        <a
                          href="https://mljar.com/docs/install/#linux"
                          target="_blank"
                          rel="noreferrer"
                          className="btn btn-primary me-2"
                        >
                          Documentation
                        </a>
                        <a href="/app/downloads/" className="btn btn-secondary">
                          Download
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Text and Centered Video */}
              <div className="d-flex justify-content-center">
                <div className="ratio ratio-16x9 mb-4 responsive-video">
                  <iframe
                    title="How to install MLJAR Studio"
                    src="https://www.youtube.com/embed/k7fHNdznYo4"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  ></iframe>
                </div>
              </div>
            </div>
          </div>

          {/* Support Section */}
          <div className="card my-4">
            <div className="card-body text-center">
              <h4 className="card-title">Need Help?</h4>
              <p className="card-text">
                If you require help or support, please create a GitHub issue or
                drop us an email.
              </p>
              <a
                href="https://github.com/mljar/studio/issues"
                target="_blank"
                rel="noreferrer"
                className="btn btn-outline-secondary mx-2"
              >
                GitHub Issues
              </a>
              <a
                href="mailto:contact@mljar.com"
                className="btn btn-outline-secondary mx-2"
              >
                Email Us
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
